import {
  globalTheme,
  Colors,
} from "srs.sharedcomponents/lib/esm/styles/config";

const virtualToolboxPage = {
  toolboxFrameColor: "#212322",
};
export const customTheme = {
  colors: {
    ...globalTheme.colors,
    primary: "#000",
    sideBarActiveTextColor: Colors.white,
    menuButtonMobile: Colors.white,
    topNavigationMobile: Colors.white,
    ...virtualToolboxPage,
  },
};
